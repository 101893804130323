import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDog } from "@fortawesome/free-solid-svg-icons";
import "../scss/Navigation.scss";
import Drawer from "../components/Drawer";

export default () => (
  <nav role="navigation">
    <Link to="/">
      <div>
        <div className="logo-row">
          <FontAwesomeIcon icon={faDog} />
          <h1 className="logo">
            <span>The</span>
            <strong>Dog</strong>ZenGarden
          </h1>
        </div>
        <h2 className="sub-heading">
          Stress & Anxiety Relief Resources for Dogs
        </h2>
      </div>
    </Link>
    <Drawer />
    {/* <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about/">About</Link>
      </li>
      <li>
        <Link to="/audio-generator/">Audio Generator</Link>
      </li>
      <li>
        <Link to="/blog/">Blog</Link>
      </li>
      <li>
        <Link to="/contribute/">Donate</Link>
      </li>
    </ul> */}
  </nav>
);
